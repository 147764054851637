import React from 'react';
import Head from 'next/head';

const Header = ({pageTitle, children}) => (
  <Head>
    <link rel={'shortcut icon'} type={'image/x-icon'} href={'/static/favicon.ico'} />
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i" />
    <title>{ `ARIES ${pageTitle ? '\u2014 ' + pageTitle : '' }`}</title>
    {children}
  </Head>
);

export default Header;