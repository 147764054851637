import React from 'react';

import {
  Box,
  BoxBody,
  FlexBox,
  Loader as BaseLoader,
} from '@traveloka/soya-components';

const BoxLoaderContent = (props) => {
  const { text, ...otherProps } = props;

  return (
    <React.Fragment>
      <BaseLoader
        type={'circular'}
        color={'green'}
        size={'lg'}
        marginBottom={'md'}
        style={{ margin: 'auto' }}
        {...otherProps}
      />
      <FlexBox halign={'center'}>{text}</FlexBox>
    </React.Fragment>
  );
};

const Loader = (props) => {
  const { type, text, noBorder, ...otherProps } = props;

  if (type === 'box') {
    if (noBorder) {
      return <BoxLoaderContent text={text} {...otherProps} />;
    }

    return (
      <Box>
        <BoxBody>
          <BoxLoaderContent text={text} {...otherProps} />
        </BoxBody>
      </Box>
    );
  }

  return (
    <BaseLoader
      type={'circular'}
      color={'green'}
      size={'lg'}
      marginBottom={'md'}
      style={{ margin: 'auto' }}
      {...otherProps}
    />
  );
};

export default Loader;
