import React from 'react';

import { createPageWithOIDCAuth } from '@traveloka/soya-auth';

import HomepageBody from 'content/homepage/components/HomepageBody';

import withLayout from 'shared/hocs/withLayout';

const HomePage = () => <HomepageBody />;

export default createPageWithOIDCAuth()(withLayout(HomePage));
