import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';

/**
 * This component is a wrapper for setting default props of SVG icon
 */

export const propTypes = {
  children: PropTypes.node,
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  fill: 'none',
  height: 24,
  stroke: 'currentColor',
  strokeLinecap: 'round',
  viewBox: '0 0 24 24',
  width: 24,
};

function SvgIcon({ children, ...props }) {
  return (
    <NoSSR>
      <svg {...props}>{children}</svg>
    </NoSSR>
  );
}

SvgIcon.propTypes = propTypes;
SvgIcon.defaultProps = defaultProps;

export default SvgIcon;