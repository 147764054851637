import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header/Header';

import { Row, Column, Text } from '@traveloka/soya-components';

const PageHeader = ({
  pageTitle,
  rightColumnComponent,
  customTitleStyle = {},
}) => (
  <React.Fragment>
    <Header pageTitle={pageTitle} />
    <Row valign={'middle'} marginBottom>
      <Column size={6}>
        <Text size={21} style={{ ...customTitleStyle }}>
          {pageTitle}
        </Text>
      </Column>
      <Column size={6} halign={'right'}>
        {rightColumnComponent}
      </Column>
    </Row>
  </React.Fragment>
);

PageHeader.propTypes = {
  pageTitle: PropTypes.string,
  rightColumnComponent: PropTypes.node,
  customTitleStyle: PropTypes.object,
};

PageHeader.defaultProps = {
  pageTitle: '',
};

export default PageHeader;
