import React from 'react';
import NextRouter from 'next/router';
import { css } from 'react-emotion';
import { connect } from 'react-redux';

import {
  Box,
  BoxBody,
  Button,
  FlexBox,
  Icon,
  Text,
} from '@traveloka/soya-components';

import Loader from 'shared/components/Loader/Loader';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import AccessDeniedBox from 'shared/components/AccessDeniedBox/AccessDeniedBox';
import AirportTransportIcon from 'shared/components/AriesIcon/AirportTransportIcon';
import PermissionWrapper from 'shared/components/PermissionWrapper/PermissionWrapper';

import path from 'shared/constants/path';
import getPagePropertyState from 'shared/selectors/PagePropertySelector';

import { getWebModes } from 'shared/utils/ClientStateUtil';
import { getPermissionsFromState } from 'shared/utils/Helper';

import {
  POINT_TO_POINT_CODE,
  RENTAL_DAILY_CODE,
} from 'shared/constants/constants';
import {
  POINT_TO_POINT_PERMISSIONS,
  RENTAL_DAILY_PERMISSIONS,
} from 'shared/constants/permissions';

const buttonHoverStyle = css`
  :hover {
    border: 10px solid #007d8d !important;
  }
`;

const HomepageBody = props => {
  const { userPermissions } = props;
  const webModes = getWebModes(userPermissions);

  let hasNoPermission = false;
  let noRender = false;

  if (webModes.length === 1) {
    noRender = true;

    let redirectUrl = '';

    if (webModes[0] === POINT_TO_POINT_CODE) {
      redirectUrl = path.airportTransport.base;
    } else if (webModes[0] === RENTAL_DAILY_CODE) {
      redirectUrl = path.carRental.base;
    } else {
      hasNoPermission = true;
    }

    if (!hasNoPermission) {
      setTimeout(() => {
        NextRouter.push(redirectUrl);
      }, 1000);
    }
  } else if (webModes.length === 0) {
    hasNoPermission = true;
  }

  const buttonProps = {
    className: buttonHoverStyle,
    marginRight: 'md',
    size: 'lg',
    style: {
      width: '350px',
      height: '350px',
      borderRadius: '50%',
    },
  };

  const buttonTextStyle = {
    color: '#007D8D',
  };

  const iconProps = {
    size: 80,
    color: '#1c2129',
  };

  return (
    <React.Fragment>
      <PageHeader />
      {noRender ? (
        <Box>
          <BoxBody>
            <FlexBox halign={'center'}>
              <Loader type={'circular'} color={'green'} marginBottom={'md'} />
            </FlexBox>
            <FlexBox halign={'center'}>{'Please Wait'}</FlexBox>
          </BoxBody>
        </Box>
      ) : (
        <React.Fragment>
          {hasNoPermission ? (
            <AccessDeniedBox />
          ) : (
            <FlexBox halign={'center'}>
              <PermissionWrapper permission={RENTAL_DAILY_PERMISSIONS}>
                <Button
                  {...buttonProps}
                  onClick={() => NextRouter.push(path.carRental.base)}
                >
                  <Icon icon={'directions-car'} {...iconProps} />
                  <br />
                  <Text style={buttonTextStyle}>{'Car Rental'}</Text>
                </Button>
              </PermissionWrapper>

              <PermissionWrapper permission={POINT_TO_POINT_PERMISSIONS}>
                <Button
                  {...buttonProps}
                  onClick={() => NextRouter.push(path.airportTransport.base)}
                >
                  <AirportTransportIcon {...iconProps} />
                  <br />
                  <Text style={buttonTextStyle}>{'Airport Transport'}</Text>
                </Button>
              </PermissionWrapper>
            </FlexBox>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const pagePropertyState = getPagePropertyState(state);
  const userPermissions = getPermissionsFromState(state);

  const locale = pagePropertyState.locale;

  return {
    locale,
    userPermissions,
  };
};

export default connect(mapStateToProps)(HomepageBody);
